import React, { useEffect, useState } from "react";
import { ProfileLayout } from "../../../components/ProfileLayout/ProfileLayout";
import { Table, TableProps } from "antd";
import styled from './BattleHistory.module.scss'
import { ReactComponent as Swap } from '../../../assets/musicbet/swap_vert.svg'
import mockImg from '../../../assets/mock/Battle_mokup 2.png'
import { profileAPI } from "../../../api/api";
import { useMediaQuery } from "react-responsive";

interface DataType {
  id: number;
  battle: any;
  bets: {
    bet: number;
    lose: number;
  };
  details: {
    bet: number;
    battle_id: number;
    end: string;
    win: number;
  };
}

// const mock: DataType[] = [
//   {
//     id: 1,
//     battle: mockImg,
//     bets: { bet: 100, lose: 120 },
//     details: { bet: 19, battle_id: 10, end: "11.11.11", win: 100 }
//   }
// ];




const BattleHistory = () => {

  const isDesktop = useMediaQuery({
    query: "(min-width: 1200px)",
});
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState(4); // Хранение текущего размера страницы

  const [data, setData] = useState<any>();

  useEffect(() => {
    profileAPI
      .getBids()
      .then((res) => {
        setData(res.data);
      })
      .then(() => {
        setIsLoading(false);
      });
  }, []);

  const outputData = data?.flatMap(item => {
    const bidTrack1 = item?.current_user_bids_amount[item?.track1?.id];
    const bidTrack2 = item?.current_user_bids_amount[item?.track2?.id];
    const winAmount = item?.current_user_win_amount;
    const looserTrackId = item?.data?.looser_track_id;
    const hasWin = item?.track1?.id === item?.data?.winner_track_id || item?.track2?.id === item?.data?.winner_track_id;

    if(item.id === 17) {
      console.log(hasWin)
      console.log(looserTrackId)
      console.log(bidTrack1)
      console.log(bidTrack2)
    }
    // Return only when both `win` and `lose` data are available
    return [
      bidTrack1 && winAmount && hasWin && {
        id: item?.id,
        battle: item?.data?.cover,
        bets: {
          bet: parseInt(bidTrack1), // bet for track1
          lose: `Win: + ${parseInt(winAmount)}`,
        },
        details: {
          bet: item?.category?.name,
          battle_id: item?.id,
          end: new Date(item?.ended_at).toLocaleDateString(),
          win: item?.track1?.id === item?.data?.winner_track_id
            ? `Win: ${item?.track1?.artists_names[0]?.name}`
            : `Win: ${item?.track2?.artists_names[0]?.name}`
        }
      },
      bidTrack2 && looserTrackId && hasWin && {
        id: item?.id,
        battle: item?.data?.cover,
        bets: {
          bet: parseInt(bidTrack2), // bet for track2
          lose: `Lose: - ${parseInt(item?.current_user_bids_amount[looserTrackId])}`,
        },
        details: {
          bet: item?.category?.name,
          battle_id: item?.id,
          end: new Date(item?.ended_at).toLocaleDateString(),
          win: item?.track1?.id === item?.data?.winner_track_id
            ? `Lose: ${item?.track2?.artists_names[0]?.name}`
            : `Lose: ${item?.track1?.artists_names[0]?.name}`
        }
      }
    ].filter(Boolean);
  });

  console.log(outputData)


  const columns: TableProps<DataType>['columns'] = [
    {
      sortIcon: ({ sortOrder }) => <Swap className={styled.swap} order={sortOrder} />,
      dataIndex: 'id',
      width: 15,
      key: 'id',
      render: (text, record) => (
        <div className={record?.id % 2 === 0 ? styled.evenRow : styled.oddRow}>
          <p>{text}</p>
        </div>
      ),
      sorter: (a, b) => b?.id - a?.id,
    },
    {
      title: 'Battle',
      dataIndex: 'battle',
      key: 'battle',
      render: (battle) =>
        <div className={styled.image_wrapp}>
          <img className={styled.image} src={battle} alt="cover" />
        </div>
    },
    {
      title: 'Bets',
      dataIndex: 'bets',
      width: 60,
      key: 'bets',
      render: (bets) => (
        <div>
          <p>Vote: {bets?.bet}</p>
          <p>{bets?.lose}</p>
        </div>
      )
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      // width: '30%',

      render: (details) => (
        <div>
          <p>{details?.bet}</p>
          <p>Battle #{details?.battle_id}</p>
          <p>End: {details?.end}</p>
          <p>{details?.win}</p>
        </div>
      )
    },
  ];


  return (
    <ProfileLayout>
      <div className={styled.table_wrapper}>
      <Table
        dataSource={isLoading ? [] : outputData}
        className={styled.table}
        columns={columns}
        pagination={{
          pageSize, // Используем размер страницы из состояния
          showSizeChanger: true, // Включаем изменение размера страницы
          position: ['bottomCenter'],
          pageSizeOptions: ['4', '8', '16'],
          onShowSizeChange: (current, size) => setPageSize(size), // Обработчик изменения размера страницы
        }}
        />
        </div>
    </ProfileLayout>
  );
}

export default BattleHistory;
