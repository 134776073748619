import { useEffect, useRef, useState } from 'react';

import NavigateElement from './NavigateElement';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';

import { ReactComponent as Person } from '../../assets/person.svg';

import { ReactComponent as Vimeo } from '../../assets/musicbet/menu_icons/social_media/Vimeo.svg'

import { ReactComponent as Instagram } from '../../assets/musicbet/menu_icons/social_media/_Instagram.svg'
import { ReactComponent as Twitter } from '../../assets/musicbet/menu_icons/social_media/_Twitter.svg'
import { ReactComponent as Telegram } from '../../assets/musicbet/menu_icons/social_media/_Telegram.svg'
import { ReactComponent as Youtube } from '../../assets/musicbet/menu_icons/social_media/_YouTube.svg'
import { ReactComponent as Tiktok } from '../../assets/musicbet/menu_icons/social_media/_TikTok.svg'
import { ReactComponent as LogoutIcon } from '../../assets/musicbet/menu_icons/logout.svg'

import { ReactComponent as AccountIcon } from '../../assets/musicbet/menu_icons/account.svg'
import { ReactComponent as BattlesResultIcon } from '../../assets/musicbet/menu_icons/battleResults.svg'
import { ReactComponent as BattlesIcon } from '../../assets/musicbet/menu_icons/battles.svg'
import { ReactComponent as ContactUsIcon } from '../../assets/musicbet/menu_icons/contactus.svg'
import { ReactComponent as GameplayIcon } from '../../assets/musicbet/menu_icons/gameplay.svg'
import { ReactComponent as HallOfFameIcon } from '../../assets/musicbet/menu_icons/halloffame.svg'
import { ReactComponent as Marketplace } from '../../assets/musicbet/menu_icons/marketplace.svg'

import logo1 from '../../assets/musicbet/BMI.png'
import logo2 from '../../assets/musicbet/ASCAP_Logo_Horizontal_White.png'


import styled from './Navigate.module.scss';
import { Button } from 'antd';
import { authAPI, profileAPI } from '../../api/api';
import { useNavigate } from 'react-router-dom';

const Navigate = () => {
  const ref = useRef(null);
  const dispatch = useAppDispatch();

  const isOpenModal = useAppSelector((state) => state.modalLogin.isOpen);
  const [isAuth, setIsAuth] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    if (localStorage.getItem('isAuth')) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, []);

  const menu = [
    { name: 'Battles', url: 'genres', path: "genres", svg: <BattlesIcon /> },
    { name: "Battles Results", url: 'battle-results', path: "battle-results", svg: <BattlesResultIcon /> },
    { name: 'Hall of Fame', url: 'hall-of-fame', path: "hall-of-fame", svg: <HallOfFameIcon /> },
    { name: 'V4S Store', url: 'store', path: "store", svg: <Marketplace /> },
    { name: 'How to play', url: 'how-to-play', path: "how-to-play", svg: <GameplayIcon /> },
    { name: 'Contact Us', url: 'contacts', path: 'contacts', svg: <ContactUsIcon /> },
  ];

  const socialIcons = [
    { icon: <Instagram className={styled.social_btn_icon} />, link: 'https://www.instagram.com/voteforsong?igsh=MmI0ejJ4Mmo1M2Rn', icons: true },
    { icon: <Twitter className={styled.social_btn_icon} />, link: 'https://x.com/Vote4Song', icons: true },
    { icon: <Telegram className={styled.social_btn_icon} />, link: 'https://t.me/vote4song', icons: true },
    { icon: <Youtube className={styled.social_btn_icon} />, link: 'https://www.youtube.com/@vote4song', icons: true },
    { icon: <Tiktok className={styled.social_btn_icon} />, link: 'https://www.tiktok.com/@vote4song?is_from_webapp=1&sender_device=pc', icons: true },
    { icon: <Vimeo className={styled.social_btn_icon} />, link: 'https://vimeo.com/user229103029', icons: true },

  ]

  const documents = [
    { name: 'Terms & Conditions', url: 'terms-n-conditions', path: "terms-n-conditions", svg: null },
    { name: 'Privacy Policy', url: 'privacy', path: "privacy", svg: null },
    { name: 'Cookie Policy', url: 'cookie', path: "cookie", svg: null },

  ]
  const profile = [
    { name: 'Account', url: 'profile', path: "profile", svg: <AccountIcon /> },
  ];

  const profileAuth = [
    { name: 'Account', url: 'login', path: "login", svg: <Person /> },
  ]


  const log_out = () => {
    authAPI
      .logOut()
      .then(() => {
        setIsAuth(false)
        navigate('/login')
      })
      .then(() => {
        profileAPI
          .getProfile()
          .then((res) => { })
      })
  }



  return (
    <div style={{ position: "relative", maxWidth: '225px' }}>
      {isOpenModal ? null : (
        <nav ref={ref} className={styled.container}>
          <div className={styled.topWrapper}>
            <div className={styled.menuWrapper}>
              <NavigateElement menuElement={isAuth ? profile : profileAuth} />
              <NavigateElement menuElement={menu} />
              <div className={styled.social_media_icons}>

                {socialIcons.map((item) => {
                  return (
                    <Button className={styled.social_btn} type='link' target='_blank' href={item.link}>{item.icon}</Button>
                  )
                })}
              </div>
              {isAuth ?

                <Button onClick={() => log_out()} className={styled.logout_btn}>
                  <div className={styled.logout_wrapper}>
                    <LogoutIcon className={styled.iconLogout} />
                    <span>Log out</span>
                  </div>
                </Button>
                :
                <></>
              }



              <NavigateElement menuElement={documents} />
              <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                <img style={{ width: "20%" }} src={logo1} />
                <img src={logo2} />
              </div>
              <p className={styled.copy}>© 2024 Vote4song.com</p>
            </div>
          </div>
        </nav>
      )}


      <div className={styled.social_media}>
        {/* {
          social.map(({ svg, url, name }) => {
            return (
              <a href={url} key={name}>
                <div style={{ margin: '20px 10px', display: "flex", justifyContent: "center", alignItems: 'center' }}>
                  {svg} <span style={{ fontSize: 18, color: "#000" }}>Follow Us</span>
                </div>
              </a>
            )
          })
        } */}
      </div>
    </div>
  );
};

export { Navigate };
