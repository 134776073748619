import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ProfileBtn } from '../Buttons/ProfileBtn/ProfileBtn';

import { ReactComponent as BuyTokensIcon } from '../../assets/musicbet/account/poker_chip.svg'
import { ReactComponent as BattleHistoryIcon } from '../../assets/musicbet/account/electric_bolt.svg'
import { ReactComponent as WithdrawIcon } from '../../assets/musicbet/account/mintmark.svg'
import { ReactComponent as MyMusicIcon } from '../../assets/musicbet/account/queue_music.svg'
import { ReactComponent as ContactActive } from '../../assets/musicbet/contact_support_active.svg'


import styled from './ProfileMenu.module.scss';
import { useMediaQuery } from 'react-responsive';

const ProfileMenu = (active?: any) => {
  const [activeBtn, setActiveBtn] = useState(null);
  const location = useLocation();
  const isDesktop = useMediaQuery({
    query: "(min-width: 1200px)",
  });
  useEffect(() => {
    if (location.pathname.includes('buy-vstokens')) setActiveBtn(0);
    if (location.pathname === ('/profile/battle-history')) setActiveBtn(1);
    if (location.pathname.includes('collect') && isDesktop) setActiveBtn(3);
    if (location.pathname.includes('collect') && !isDesktop) setActiveBtn(2);
    if (location.pathname.includes('my-collection') &&  isDesktop) setActiveBtn(2);
    if (location.pathname.includes('my-collection') &&  !isDesktop) setActiveBtn(3);
    if (location.pathname.includes('help')) setActiveBtn(4)
  }, [location.pathname]);


  const buttons = [
    { title: 'Buy VSTokens', url: '/profile/buy-vstokens', svg: <BuyTokensIcon /> },
    { title: 'Battle History', url: '/profile/battle-history', svg: <BattleHistoryIcon /> },
    { title: 'Collect', url: '/profile/collect', svg: <WithdrawIcon /> },
    { title: 'My Collection', url: '/profile/my-collection', svg: <MyMusicIcon /> },
  ];

  const desktopButtons = [
    { title: 'Buy VSTokens', url: '/profile/buy-vstokens', svg: <BuyTokensIcon /> },
    { title: 'Battle History', url: '/profile/battle-history', svg: <BattleHistoryIcon /> },
    { title: 'My Collection', url: '/profile/my-collection', svg: <MyMusicIcon /> },
    { title: 'Collect', url: '/profile/collect', svg: <WithdrawIcon /> },
    { title: "Help", url: '/profile/help', svg: <ContactActive /> }
  ]

  if (!isDesktop) {
    return (
      <div className={styled.container}>

        {buttons.map((el, index) => {
          return (
            <ProfileBtn
              url={el.url}
              key={index}
              index={index}
              setIsActive={setActiveBtn}
              isActive={activeBtn}
              title={el.title}
              svg={el.svg}
            />
          );
        })}
      </div>
    );
  }
  else {
    return (
      <div className={styled.container}>

        {desktopButtons.map((el, index) => {
          return (
            <ProfileBtn
              url={el.url}
              key={index}
              index={index}
              setIsActive={setActiveBtn}
              isActive={activeBtn}
              title={el.title}
              svg={el.svg}
            />
          );
        })}
      </div>
    );
  }
};

export { ProfileMenu };
