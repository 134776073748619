import { ProfileLayout } from '../ProfileLayout/ProfileLayout';

import { Button, Col, Form, Input, Row } from 'antd';

import { useAppSelector } from '../../hooks/reduxHooks';

import s from './ContactUs.module.scss';
import { ChangeEvent, useEffect, useState } from 'react';
import { contactAPI } from '../../api/api';
import { toast } from 'react-toastify';
import { NotificationT } from '../ToastifyNot/NotificationToastify';
import { useMediaQuery } from 'react-responsive';


interface IValue {
  message: string;
}

const ContactUs = () => {

  const [text, setText] = useState<string>("")
  const [disabled, setDisabled] = useState<boolean>(true)

  const [form] = Form.useForm();

  const { nickname, player_status, giver_status, sponsor_status } = useAppSelector((state) => state.player);
  function sendSupport(value: IValue) {
    setDisabled(true)
    contactAPI
      .contactSupport(value.message)
      .then((res) => {
        toast.success('Success');
        form.resetFields();
      })
      .catch((error) => {
        toast.error("Something wrong");
      });
  }

  const sendMessage = (value: any) => {
    sendSupport(value)
    setDisabled(true)
  }

  useEffect(() => {
    if (text.length === 0) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [text.length, setText])


  return (
    <ProfileLayout>
      {/* <NotificationT /> */}
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Form
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="vertical"
            onFinish={sendMessage}
          >

            <Form.Item
              required
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
              name={'message'}

            >
              <Input.TextArea
                showCount
                maxLength={256}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) => setText(event.target.value)}
                style={{ height: 150, resize: 'none' }}
                value={text}
                placeholder=' Type your message here and check your email within 24 hours'
                allowClear
              />
            </Form.Item>

            <Form.Item wrapperCol={{ span: 24 }}>

                  <Button className={s.send_btn} type="default" htmlType="submit" disabled={disabled}>
                    Send
            </Button>
            </Form.Item>

          </Form>
        </Col>
      </Row>
    </ProfileLayout>
  )
};

export default ContactUs;
