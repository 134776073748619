import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
/* import { ReactComponent as Logo } from '../../assets/logoPlayporn.png'; */

import styled from './Layout.module.scss';
interface IProps {
    children: JSX.Element;
}

const Layout: React.FC<IProps> = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState({ text: '', primaryColor: '', color: '' })
    useEffect(() => {


        if (location.pathname.includes('acctount')) {
            setCurrentPage({ text: 'Account', primaryColor: '#9507FF', color: "#fff" })
        } else if (location.pathname.includes('genres')) {
            setCurrentPage({ text: 'Tournament geners', primaryColor: '#B8B8C0', color: "#fff" })
        } else if (location.pathname.includes('tournament')) {
            setCurrentPage({ text: 'Pop tournament', primaryColor: '#FF75E6', color: "#fff" })
        } else if (location.pathname.includes('history')) {
            setCurrentPage({ text: 'Battle results', primaryColor: '#F56040', color: "#fff" })
        } else if (location.pathname.includes('hall-of-fame')) {
            setCurrentPage({ text: 'Hall of fame', primaryColor: '#FFD300', color: "#fff" })
        } else if (location.pathname.includes('store')) {
            setCurrentPage({ text: 'V4S Store', primaryColor: '#EC1F26', color: "#fff" })
        } else if (location.pathname.includes('how-to-play')) {
            setCurrentPage({ text: 'How to play', primaryColor: '#00A91F', color: "#fff" })

        }
    }, [location.pathname])

    const { balance, avatar } = useAppSelector((state) => state.player);
    const [isAuth, setIsAuth] = useState(false)


    useEffect(() => {
        if (localStorage.getItem('isAuth')) {
            setIsAuth(true);
        } else {
            setIsAuth(false);
        }
    }, []);


    // function openProfile() {
    //     navigate('/customer-tracks');
    // }
    const dispatch = useAppDispatch();
    const isOpenMobileMenu = useAppSelector((state) => state.mobileMenu.isOpen);


    const isDesktop = useMediaQuery({
        query: '(min-width: 1200px)',
    });
    return (
        // <div className={styled.container}>
        //     {location.pathname.includes('login') ? <Logo className={styled.big_logo} /> : <></>}
        //     <div className={styled.layout_wrapper}>
        //         {isAuth && <Account isAuth={isAuth} />}
        //         {!isDesktop && (
        //             <div>
        //                 <div style={{ width: "100%" }}>
        //                     {
        //                         !isAuth && (
        //                             <Row gutter={[10, 10]} style={{ marginTop: 5 }}>
        //                                 <Col span={24} className={styled.logo_col}>
        //                                     <div className={styled.logo_col}>
        //                                         <Button className={styled.button_link} type="link" href={!isDesktop ? '/genres' : isAuth ? '/genres' : '/login'}>
        //                                             <img className={styled.logoAnonim} src={logosvg} alt='' style={{ marginTop: 8 }} width={150} />
        //                                         </Button>
        //                                     </div>

        //                                 </Col>
        //                             </Row>
        //                         )
        //                     }
        //                 </div>

        //             </div>
        //         )}

        //         <Drawer
        //             placement={"top"}
        //             style={{ minHeight: "100vh" }}
        //             onClose={() => dispatch(closeMobileMenu(null))}
        //             open={isOpenMobileMenu}
        //         >
        //             <MobileMenu />
        //         </Drawer>
        //         <div className={styled.logo_col}>
        //             {
        //                 isDesktop ? <></> :
        //                     <div className={styled.button_wrapper}>
        //                         <div className={styled.account_wrapper}>
        //                             {
        //                                 avatar ? <img className={styled.account_avatar} src={avatar} alt="ava" /> : <></>
        //                             }

        //                         </div>
        //                         {
        //                             avatar ? <span style={{ paddingLeft: 10, fontSize: 20 }}>
        //                                 {parseFloat(balance)}</span>
        //                                 : <></>
        //                         }

        //                     </div>
        //             }
        //             {
        //                 isDesktop ? <MusicHeader primaryColor={currentPage.primaryColor} title={currentPage.text} color={currentPage.color} /> : <></>
        //             }

        //         </div>
        //         {!isDesktop ?
        //             <img
        //                 className={styled.menuIconOpen}
        //                 onClick={() => dispatch(openMobileMenu(null))}
        //                 src={MenuIcon}
        //                 alt='menu'
        //             />
        //             :
        //             <></>}
        //     </div>
        //     {/* {isOpenModalLogin && <LoginModal />} */}
            <div className={styled.wrapper}>
                <>{children}</>
            </div>


        // </div>
    );
};

export { Layout };