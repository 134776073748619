import { ProfileLayout } from '../ProfileLayout/ProfileLayout';
import { useAppSelector } from '../../hooks/reduxHooks';

import s from './ProfileWallet.module.scss';

import peggy from '../../assets/mock/Peggy-Gou-LP-web-e1712588331306.jpg'
import illit from '../../assets/mock/battle.jpg'
import MyMusic from '../MyMusic';
import { useEffect, useState } from 'react';
import { profileAPI } from '../../api/api';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const ProfileWallet = () => {
  const [data, setData] = useState<any>()
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const { firstName, lastName, nickname, transactions, sponsor_status, giver_status, player_status } = useAppSelector((state) => state.player);


  useEffect(() => {
    profileAPI
      .getTrack()
      .then((res) => {
        setData(res)
      })
      .then(() => {
        setIsLoading(false)
      })
  }, [])

  console.log(data)



  return (
    <ProfileLayout>
      <div className={s.buy_tokens_header}>
        <h2 className={s.buy_tokens_header__text}>My Collection</h2>
      </div>
      {
        isLoading ? <></> :
    data.data.map((item) => {
      return(
        <MyMusic
        image={item.cover}
        genre={item.categories_names[0]?.name}
        artist={item.artists_names[0].name}
        track={item.name}
        year={item.year}
        price={item.price}
        win={item.win_count}
        income={item.income}
        />
      )

    })
  }
  {
    data?.meta?.total === 0 && <Button onClick={() => navigate('/store')} className={s.marketplace_btn}>V4S Store</Button>
  }
    </ProfileLayout>
  )
};

export default ProfileWallet;
