import React, { useState } from "react";
import { ProfileLayout } from "../../../components/ProfileLayout/ProfileLayout";
import styles from './bonus.module.scss'
import { Button, Input } from "antd";
import { profileAPI } from "../../../api/api";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import { setUserData } from "../../../store/playerSlice";
import { NotificationT } from "../../../components/ToastifyNot/NotificationToastify";
import { toast } from "react-toastify";

const Bonus = () => {

  const [bonusCode, setBonus] = useState(null);

  const dispatch = useAppDispatch();


  const usePromo = () => {
    profileAPI
      .usePromo({ code: bonusCode })
      .then((res) => {
        profileAPI
          .getProfile()
          .then((res) => {
            dispatch(
              setUserData({
                first_name: res?.first_name,
                last_name: res?.last_name,
                nickname: res?.nickname,
                email: res.email,
                gender: res.gender,
                phone: res.phone,
                id: res.id,
                balance: res.balance,
                avatar: res?.avatar,
                first_voted: res?.data?.first_voted,
                income: res?.transaction_sums?.income,
                expense: res?.transaction_sums?.expense,
                transactions: res?.videobet_transactions,
                stocks: res?.stocks
              })
            )
          })
          .then(() => {
            toast.success("You successfully used your voucher")
          })
          .catch((err) => {
            toast.error("Something wrong");
          })
      }
      )
  }

  return (
    <ProfileLayout>
      {/* <NotificationT /> */}

      <div className={styles.buy_tokens_header}>

        <h2 className={styles.buy_tokens_header__text}>BONUS - FREE TOKENS</h2>
      </div>

      <div className={styles.bonus_filling}>
        <p className={styles.bonus_filling_header}>How to get free tokens?</p>
        <p>1.  Follow us on <a href="https://www.instagram.com/voteforsong?igsh=MmI0ejJ4Mmo1M2Rn" target="_blank">Instagram</a> or <a href="https://www.tiktok.com/@vote4song?is_from_webapp=1&sender_device=pc" target="_blank">TikTok</a></p>
        <p>2.  Like this post.</p>
        <p>3. Send us a message «WELCOME BONUS»</p>
        <p>4. Start voting!</p>
        <h4 style={{ marginBottom: 10, color: '#fff', fontSize: 14 }}>SWAP RATE: <br />
        TikTok and Instagram: each of your subscribers is equal to 1 free VST.</h4>
      </div>
      <Input onChange={(event) => setBonus(event.target.value)} placeholder="Enter Promo code from message" />
      <Button onClick={usePromo} className={styles.button}>Get free tokens</Button>
    </ProfileLayout>
  )
}

export default Bonus;
