import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
/* import { ReactComponent as Logo } from '../../assets/logoPlayporn.png'; */

import { closeMobileMenu, openMobileMenu } from '../../../store/mobileMenuSlice';
import { MobileMenu } from '../../mobileMenu/MobileMenu';
import { ReactComponent as Logo } from '../../../assets/musicbet/VOTE4SONG_white_transparent.svg';
import { closeLoginModalMenu } from '../../../store/loginModalSlice';

import logosvg from '../../../assets/musicbet/VOTE4SONG_white_transparent.svg'
import MenuIcon from '../../../assets/musicbet/menu.svg'
import { sprintf } from 'sprintf-js'


import styled from './Header.module.scss';

import { LoginModal } from "../../../pages/Login/LoginModal/LoginModal";
import { Button, Col, Drawer, Row } from 'antd';
import Account from '../../Account';
import MusicHeader from '../../MusicHeader';
import { battleAPI, genresAPI, musicAPI, profileAPI } from '../../../api/api';
import { setUserData } from '../../../store/playerSlice';


const Header = () => {
  const [searchParams] = useSearchParams();
  const [genreInfo, setGenreInfo] = useState<any>()
  const [genres, setGenres] = useState<any>()
  const [isLoading, setLoading] = useState(true)
  const [tournament, setTournament] = useState<any>()
  const [currentBattle, setCurrentBattle] = useState<any>()
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState({ text: '', primaryColor: '', color: '', returnable: false })




  useEffect(() => {
    const updateCurrentPage = () => {
      let pageInfo = { text: '', primaryColor: '#000', color: '#fff', returnable: false };

      if (location.pathname.includes('profile')) {
        pageInfo = { text: 'Account', primaryColor: '#9507FF', color: "#fff", returnable: false };
      } else if (location.pathname.includes('genres')) {
        pageInfo = { text: 'Tournament genres', primaryColor: '#B8B8C0', color: "#fff", returnable: false };
      } else if (location.pathname.includes('tournament')) {
        const colorId = searchParams.get('id');
        genresAPI.getGenres().then((res) => {
          setGenres(res);
          const genre = res.find((item) => item.id.toString() === colorId);
          setGenreInfo(genre);
          pageInfo = {
            text: `${genre?.name}`,
            primaryColor: genre?.data?.primary_color || '#FF75E6',
            color: "#fff",
            returnable: false,
          };
          setCurrentPage(pageInfo);
          localStorage.setItem('currentPage', JSON.stringify(pageInfo));
        });
        return; // Выходим, чтобы избежать дублирования `setCurrentPage`
      } else if (location.pathname.includes('battle/')) {
        const battle = searchParams.get('display')
              const pageInfo = {
                text: `Battle #${battle}`,
                primaryColor: '#A1002E',
                color: "#fff",
                returnable: false,
              };
              setCurrentPage(pageInfo);
              localStorage.setItem('currentPage', JSON.stringify(pageInfo));
          return
            }

      else if (location.pathname.includes('history')) {
        pageInfo = { text: 'Battle results', primaryColor: '#F56040', color: "#fff", returnable: false };
      } else if (location.pathname.includes('hall-of-fame')) {
        pageInfo = { text: 'Hall of fame', primaryColor: '#FFD300', color: "#fff", returnable: false };
      } else if (location.pathname.includes('store')) {
        pageInfo = { text: 'V4S Store', primaryColor: '#EC1F26', color: "#fff", returnable: false };
      } else if (location.pathname.includes('how-to-play')) {
        pageInfo = { text: 'How to play', primaryColor: '#00A91F', color: "#fff", returnable: false };
      } else if (location.pathname.includes('battle-results')) {
        pageInfo = { text: 'BATTLE RESULTS', primaryColor: '#F56040', color: "#fff", returnable: false };
      } else if (location.pathname.includes('contacts')) {
        pageInfo = { text: 'contact us', primaryColor: '#8894FF', color: "#fff", returnable: false };
      } else if (location.pathname.includes('terms-n-conditions')) {
        pageInfo = { text: 'TERMS & CONDITIONS', primaryColor: '#0E664B', color: "#fff", returnable: false };
      } else if (location.pathname.includes('privacy')) {
        pageInfo = { text: 'Privacy Policy', primaryColor: '#0E664B', color: "#fff", returnable: false };
      } else if (location.pathname.includes('cookie')) {
        pageInfo = { text: 'Cookie Policy', primaryColor: '#0E664B', color: "#fff", returnable: false };
      }

      setCurrentPage(pageInfo);
      localStorage.setItem('currentPage', JSON.stringify(pageInfo));
    };

    updateCurrentPage();

    // Загружаем из localStorage при первой загрузке страницы
    const savedPage = localStorage.getItem('currentPage');
    if (savedPage) {
      setCurrentPage(JSON.parse(savedPage));
    }
  }, [location.pathname, searchParams]);
  const { balance, avatar } = useAppSelector((state) => state.player);
  const [isAuth, setIsAuth] = useState(false)


  useEffect(() => {
    const interval = setInterval(() => {
      if (localStorage.getItem('isAuth')) {
        setIsAuth(true);
      } else {
        setIsAuth(false);
      }
    }, 1000); // checks every second

    return () => clearInterval(interval); // clears the interval when component unmounts
  }, [balance, avatar]);;

  useEffect(() => {
    profileAPI
      .getProfile()
      .then((res) => {
        dispatch(
          setUserData({
            first_name: res?.first_name,
            last_name: res?.last_name,
            nickname: res?.nickname,
            email: res.email,
            gender: res.gender,
            phone: res.phone,
            id: res.id,
            balance: res.balance,
            avatar: res?.avatar,
            first_voted: res?.data?.first_voted,
            income: res?.transaction_sums?.income,
            expense: res?.transaction_sums?.expense,
            transactions: res?.videobet_transactions,
            stocks: res?.stocks
          })
        )
      })
      .catch(() => {
        setIsAuth(false)
      })
  }, [])




  // function openProfile() {
  //     navigate('/customer-tracks');
  // }

  const dispatch = useAppDispatch();
  const isOpenMobileMenu = useAppSelector((state) => state.mobileMenu.isOpen);


  const isDesktop = useMediaQuery({
    query: '(min-width: 1200px)',
  });

  if (location.pathname.includes('login')) {
    return null
  }

  return (
    isDesktop ?
      <div className={styled.container}>
        <div className={styled.nav_col}>
          <Button onClick={() => navigate('/genres')} type='link'>
            <img alt='logo' style={{maxWidth: 200}} src={logosvg} />
          </Button>
        </div>
        <div className={styled.main_col}>
          <MusicHeader title={currentPage.text} color={currentPage.color} primaryColor={currentPage.primaryColor} />
        </div>
        {avatar && <div className={styled.account_col}>
          <Button onClick={() => navigate('profile')} className={styled.ava_wrapper}>
            <div className={styled.avatar_wrapper}>
              <img className={styled.avatar} src={avatar} alt='avatar' />
            </div>
            <p className={styled.balance}>{parseInt(balance)}</p>
          </Button>

        </div>
        }
      </div>
      :
      <div className={styled.mobileHeader}>

        {location.pathname.includes('login') ? <Logo className={styled.big_logo} /> : <></>}
        <div className={styled.layout_wrapper}>
          {isAuth && <Account isAuth={isAuth} />}
          {!isDesktop && (
            <div>
              <div style={{ width: "100%" }}>
                {
                  !isAuth && (
                    <Row gutter={[10, 10]}>
                      <Col span={24} className={styled.logo_col}>
                        <div className={styled.logo_col}>
                          <Button className={styled.button_link} type="link" onClick={() => navigate('/genres')}>
                            <img className={styled.logoAnonim} src={logosvg} alt='' style={{ marginTop: 8 }} width={150} />
                          </Button>
                        </div>

                      </Col>
                    </Row>
                  )
                }
              </div>

            </div>
          )}
          {(avatar && isAuth) && <Button onClick={() => navigate('/profile')} className={styled.ava_wrapper_mobile}>
            <div className={styled.avatar_wrapper}>
              <img className={styled.avatar} src={avatar} alt='avatar' />
            </div>
            <p className={styled.balance}>{parseInt(balance)}</p>
          </Button>

          }

          <img
            className={styled.menuIconOpen}
            onClick={isOpenMobileMenu ? () => { dispatch(closeMobileMenu(null)); dispatch(closeLoginModalMenu(null)) } : () => dispatch(openMobileMenu(null))}
            src={MenuIcon}
            alt='menu'
          />
        </div>



        <Drawer
          placement={"top"}
          style={{ minHeight: "100vh" }}
          onClose={() => dispatch(closeMobileMenu(null))}
          open={isOpenMobileMenu}

        >
          <MobileMenu />
        </Drawer>
      </div>
  );
};

export default Header;