
import { Button, Input, InputNumber, Modal, Table } from "antd";


import { ProfileLayout } from "../ProfileLayout/ProfileLayout";

import { ReactComponent as TRC20 } from '../../assets/musicbet/account/trc20.svg'
import { ReactComponent as Moonpay } from '../../assets/musicbet/account/Moonpay.svg'
import { ReactComponent as Payments } from '../../assets/musicbet/account/pasyment.svg'
import ReactClipboard from 'react-clipboardjs-copy';
import { CloseOutlined } from "@ant-design/icons";
import Lottie from "lottie-react";

import s from './BuyTokens.module.scss'
import { useEffect, useState } from "react";
import { NotificationT } from "../ToastifyNot/NotificationToastify";
import { toast } from "react-toastify";
import useClipboard from "react-use-clipboard";
import { profileAPI } from "../../api/api";
import waitng from '../../assets/waiting.json'
import success from '../../assets/lottie-success.json'
import { useMediaQuery } from "react-responsive";
import { isDesktop } from "react-device-detect";
import { MoonPayBuyWidget } from "@moonpay/moonpay-react";
import { render } from "react-dom";
import moment from "moment";

const BuyTokens = () => {
	const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
	const [moonpayOpen, setMoonpayOpen] = useState(false)
	const [amount, setAmount] = useState(10)
	const [idInvoice, setInvoice] = useState<any>();
	const [link, setLink] = useState<any>();
	const [open, setOpen] = useState(false);
	const [isStatusOpen, setStatusOpen] = useState(false);
	const [status, setStatus] = useState<any>()
	const [transactions, setTransactions] = useState<any>()

	const isMobile = useMediaQuery({
		query: "(max-width: 430px)",
	});

	const columns = [
		{
			title: 'Transaction',
			dataIndex: 'id',
			key: 'id',
			render: (text, record) => (
				<span>#{text}</span>

			),
		},
		{
			title: 'Date',
			dataIndex: 'created_at',
			key: 'created_at',
			render: (text) => (
				<span>{moment(text).format('DD.MM.YYYY')}</span>
			)

		},
		{
			title: 'Details',
			dataIndex: 'amount',
			key: 'amount',
			render: (text, record) => (
				<span>{parseInt(text)} MBT</span>

			),
		},
	];

	const buy = () => {
		profileAPI.buyTokens(amount, "USD")
			.then((res) => {
				// Устанавливаем значение инвойса
				setInvoice({ id: res.id, link: res.invoice_data.result.link });
				return res; // Возвращаем res для дальнейшего использования
			})
			.then((res) => {
				return sleep(1000).then(() => {
					setOpen(true);
					return res.id; // Возвращаем id для использования в следующем then
				});
			})
			.then((id) => {
				return checkStatusUntilSuccess(id); // Передаем id в функцию проверки статуса
			})
			.catch((err) => {
				toast.error('Something is wrong');
			});
	};

	const checkStatusUntilSuccess = async (id) => {
		let res;

		do {
			res = await profileAPI.checkStatus(id); // Используем переданный id
			setStatus(res.state);
			if (res.state !== 'confirmed') {
				await sleep(3000);
			}
		} while (res.state !== 'confirmed');

		return res;
	};

	useEffect(() => {
		profileAPI.getHistory()
			.then((res) => {
				setTransactions(res.data)
			})
	}, [])




	return (
		<>
			<ProfileLayout>
				{/* <NotificationT/> */}
				<div>
					<div>
						<div className={s.buy_tokens_header}>

							<h2 className={s.buy_tokens_header__text}>Buy VSTokens</h2>
						</div>
						<div style={{ padding: '10px 0' }}>
							<div className={s.buy_tokens_inner_header}>

								<h2>Deposit USDT (TRC20)</h2>
								<TRC20 className={s.trc20} />
							</div>
							<p className={s.step}>Min. deposit - 10 USDT (100 VST) </p>
						</div>
						<div className={s.amount_input}>
							<p>Amount:</p>
							{/* <InputNumber width={'100%'} min={10000} placeholder="Amount (min. 10000)" type="number" onChange={(event) => { console.log(event) }} /> */}
							<div className={s.amount_input_field}>
								<button disabled={amount < 11 ? true : false} onClick={() => { setAmount(amount - 1) }}>-</button>
								<h4>{amount}</h4>
								<button onClick={() => setAmount(amount + 1)}>+</button>
							</div>
						</div>
						<Button onClick={buy} className={s.copy_code}>Buy VST</Button>
					</div>
					<div className={s.buy_usdt_inner_header}>
						<h2>Buy USDT (TRC20)</h2>
						<div className={s.usdt}>
							<Moonpay />
							<Payments className={s.moonpay_img} />
						</div>

					</div>
					<Button href="https://www.moonpay.com/" target="_blank" className={s.copy_code}>Buy USDT</Button>

				</div>

				<Modal width={"100%"} style={{ top: 80, padding: "0 !important" }} open={open} footer={<></>} title={<></>} onCancel={() => setStatusOpen(true)} destroyOnClose closable={true} closeIcon={<Button className={s.closeIcon} onClick={() => setOpen(false)}><CloseOutlined style={{ color: '#fff' }} /></Button>} >
					<iframe className={s.iframe} src={idInvoice?.link} name="iframe_a" title="Iframe Example"></iframe>
				</Modal>
				<Modal destroyOnClose closable={true} footer={null} closeIcon={<Button className={s.closeIcon} onClick={() => setStatusOpen(false)}><CloseOutlined style={{ color: '#fff' }} /></Button>} open={isStatusOpen}>


					{/* <p style={{ color: '#fff' }}>{status}</p> */}
					{
						status === 'confirmed' ?
							<div className={s.status}>
								<h2 style={{ color: '#fff' }}>Success</h2>
								<Lottie style={{ width: 200 }} animationData={success} loop={false} />
							</div>
							: status === "waiting" ?
								<div className={s.status}>
									<h2 style={{ color: '#fff' }}>Waiting...</h2>
									<Lottie className={s.lottie} style={{ width: 200 }} animationData={waitng} loop={true} />
								</div> : status === 'cancelled' ? <>
								</> : null

					}
				</Modal>


				<MoonPayBuyWidget
					variant="overlay"
					style={{ width: "100%", display: 'block', margin: '0 auto' }}
					baseCurrencyCode="usd"
					baseCurrencyAmount="100"
					defaultCurrencyCode="eth"
					onLogin={async () => console.log("Customer logged in!")}
					visible={moonpayOpen ? true : false}
					onCloseOverlay={() => setMoonpayOpen(false)}
				/>

				<div style={{ marginTop: 20 }} className={s.table_wrapper}>


					<Table className="deposit_table" dataSource={transactions} columns={columns} />;
				</div>




			</ProfileLayout >

			{/* <Modal
					open={isOpenModal && !!iframeSrc}
					title=""
					onCancel={handleCloseModal}
					footer={false}
					maskClosable={false}
					centered
					styles={{ content: { padding: 0 } }}
				>
					<iframe
						title="Title"
						src={iframeSrc}
						style={{
							borderRadius: 7,
							width: "100%",
							height: "95vh",
							border: "none",
						}}
					>
					</iframe>
				</Modal> */}
		</>
	)
}

export default BuyTokens;

// const getSignature = (transAmount, currency, transRefNum) => {
// 	const merchantNumber = process.env.REACT_APP_MID_MERCHANT_ID
// 	const PersonalHashKey = process.env.REACT_APP_HASH_KEY
// 	const notificationUrl = process.env.REACT_APP_BE_BASE_URL + '/' + process.env.REACT_APP_NOTIFICATION_URL

// 	const transComment = ""
// 	const payFor = "FANTASY+BATTLES"
// 	const dispLng = "en-US"
// 	const Brand = ""
// 	const urlRedirect = ""
// 	const transType = "0"
// 	const dispPaymentType = ""
// 	const dispRecurring = "0"
// 	const dispMobile = "auto"
// 	const transInstallments = "1"
// 	const uiVersion = "6"

// 	const signature = sha256(merchantNumber + urlRedirect + notificationUrl + transComment + transRefNum + Brand + transInstallments
// 		+ uiVersion + transType + transAmount + currency + dispPaymentType + payFor + dispRecurring + dispLng + dispMobile + PersonalHashKey)

// 	const Base64Siganture = signature.toString(Base64);

// 	const hash = encodeURIComponent(Base64Siganture);

// 	const src = "https://uiservices.aureavia.com/hosted/?merchantID=" + merchantNumber + "&url_redirect=" + urlRedirect + "&notification_url="
// 		+ notificationUrl + "&trans_comment=" + transComment + "&trans_refNum=" + encodeURIComponent(transRefNum) + "&Brand=" + Brand + "&trans_installments=" + transInstallments
// 		+ "&ui_version=" + uiVersion + "&trans_type=" + transType + "&trans_amount=" + transAmount + "&trans_currency=" + currency + "&disp_paymentType="
// 		+ dispPaymentType + "&disp_payFor=" + encodeURIComponent(payFor) + "&disp_recurring=" + dispRecurring + "&disp_lng=" + dispLng + "&disp_mobile="
// 		+ dispMobile + "&signature=" + hash

// 	return src
// }
