import { Col, Row } from 'antd';
import { MantineProvider } from "@mantine/core";

import Vimeo from '@u-wave/react-vimeo';


import styled from './GameRules.module.scss';
import { useMediaQuery } from 'react-responsive';
import MusicHeader from '../../components/MusicHeader';

import mock from '../../assets/musicbet/Gameplay animation.jpg'
import { useNavigate } from 'react-router-dom';

const GameRules = () => {

  const navigate = useNavigate();
  const isDesktop = useMediaQuery({
    query: '(min-width: 1201px)',
  });

  return (
    <>

      {!isDesktop && <MusicHeader title='How to Play' primaryColor='#00A91F' color='#fff' />}

      <div className={styled.wrapper}>
        <Row >
          <Col span={24}>
          </Col>
        </Row>
        <Row className={styled.rules_container}>
          <Col span={24} style={{ marginBottom: 32 }}>
            <Vimeo
              video={'https://vimeo.com/1033052955/fb4d09605a?share=copy'}
              className={styled.player}
              // width={isDesktop ? "100%" : 'max-content !important'}
              // height={isDesktop ? "100%" : 'max-content !important'}
              // style={{width: '100%', height: "100%"}}
              width={isDesktop ? '800px' : "fit-content"}
            // height={isDesktop ? '500px' : "min-content"}
            />
          </Col>
          <Col span={24}>
            <h2 className={styled.subHeader}>
              PERPERTUAL PARTICIPATION:</h2>
            <p className={styled.text}>
              Guaranteed participation of the registered user any time the track is placed in a battle. <br />
              Each track can have only one registered user. <br />
              To obtain registration for any track please check the <a  onClick={() => navigate('/store')}>V4S Store</a>. <br />
              Standard rules of one-time participation apply. <br /> <br />
              The cards are transferable six months after purchase. <br />
              Your cards will be featured in tournaments at least once a month. <br />
              If the card is the winner of the tournament, it automatically gets enrolled in the next tournament in the same genre. <br />
              Additionally, the cards you own can participate in all upcoming 25 games on the platform. <br />


            </p>

            <h2 className={styled.subHeader}>
              ONE-TIME PARTICIPATION:
            </h2>
            <p className={styled.text}>
              Vote4Song is the first world’s fantasy music platform featuring battles, where participants use digital assets to show support for their favorite tracks by voting. <br />
              The platform offers a wide range of musical genres and tournaments.<br />
              Each tournament consists of 8 song playoff.<br />
              There are 3 rounds and 7 battles in each tournament.<br />
              Each battle lasts for 48 hours.<br />
              The results are determined by the number of votes casted for each song.<br />
              The winner of each battle progresses to the next round, while the loser is eliminated from the tournament.<br />
              The battle pot is distributed among the participants who vote for the winner and receive VSTokens as rewards.

            </p>

            <h2 className={styled.subHeader}>
              PARTICIPATION:</h2>
            <p className={styled.text}>
              Participants must meet the age requirement according to their jurisdiction in which they reside. <br />
              Access to Vote4Song.com is prohibited in regions where such activities are restricted by local laws. <br />
            </p>

            <h2 className={styled.subHeader}>
              THE PLATFORM’S DIGITAL ASSETS - VSTOKENS:
            </h2>
            <p className={styled.text}>        The platform’s currency on Vote4Song.com is the VST (Vote Song Token). <br />
              These tokens are exclusive to the Vote4Song.com platform and cannot be exchanged for fiat currency. <br />

            </p>

            <h2 className={styled.subHeader}>
              TOKEN ACQUISITION:
            </h2>
            <p className={styled.text}>
              Tokens can be acquired at the rate of 10 VST = 1 USDT (Tether).               </p>
            <h2 className={styled.subHeader}>
              MINIMUM VST TO ENTER A BATTLE:
            </h2>
            <p className={styled.text}>
              The minimum is 1 VST.

            </p>
            <h2 className={styled.subHeader}>
              BATTLE POT DISTRIBUTION:</h2>
            <p className={styled.text}>
              The battle pot is distributed among the participants who vote for the winning track. <br />
              VSTokens are distributed as rewards proportional to the amount of VSTokens participants have placed. <br /><br />

              Battles pot distribution structure: <br />
              -80% is distributed among the one-time participants who voted for the winning track, perpetual participants can also cast votes and are eligible to receive reward <br />
              -10% is distributed among perpetual participants if a track from their collection wins the battle <br />
              -5% is distributed among perpetual participants even if a track from their collection loses the battle <br />
              -4% is allocated to the platform as a fee to support the system <br />
              -1% is allocated to the Platform Grand Pot, which will be awarded through a special drawing among tournament participants. <br />

            </p>

            <h2 className={styled.subHeader}>
              COLLECT VST FROM YOUR ACCOUNT:
            </h2>
            <p className={styled.text}>
              You can transfer VSTokens from your V4S account balance by providing the address of your external wallet and specifying the amount of VST to be transferred.
            </p>

            <h2 className={styled.subHeader}>
              PARTICIPANTS STRATEGY AND SUCCESS:</h2>
            <p className={styled.text}>

              Please note that Vote4Song is an entertainment platform where success is determined by participants' strategy, skills, and knowledge of the music industry. <br />
              To increase your chances of earning rewards, analyze the popularity of musicians in battles, their music, and the strategies and behaviors of other participants.

            </p>

            <h2 className={styled.subHeader}>
              COMPLIANCE:

            </h2>
            <p className={styled.text}>
              Players must comply with local laws, platform rules and ethical standards.
            </p>

            <h2 className={styled.subHeader}>
              DISPUTE RESOLUTION:

            </h2>
            <p className={styled.text}>
              Vote4Song reserves the right to resolve disputes and make final decisions on all matters related to the platform. <br />
              By participating, players agree to comply with the platform’s rules, Terms & Conditions, and Privacy Policy. <br /><br />

            </p>

            {/* <h2 className={styled.subHeader}>
            IMPORTANT!!!
            </h2>
            <p className={styled.text}>
            The winning video is being decided by majority of the votes - not the amount of the bets!<br />
            The funds (the bets) of the players who bet on lost video is going to the prize pool which is being distributed between winning players and video owners.
            </p>

            <h2 className={styled.subHeader}>BATTLE EXAMPLE:</h2>
            <p className={styled.text}>Video #001 gained 2000 tokens in one week from 100 players who bid. At the same time, video #002 gained 1000 tokens in the same period of time, collecting votes from 200 players. </p>
            <p className={styled.text}>Respectively, video #002 from the example wins the battle as more people have voted for it.</p>
            <p className={styled.text}>The prize fund consists of the bets of losing players.</p>
            <p className={styled.text}>The owner models of the #002 video receives 10% of the prize fund. <br />The net income of the owner of video #002 in this battle is 10% out of 2000 tokens = 200 tokens.</p>
            <p className={styled.textBronze}>The owner models of the #001 video which has lost the battle receives 5% out of the prize fund. In our example video #001 owner's incentive prize will be 100 tokens </p>
            <p className={styled.textBronze}>Another 70% of the prize fund is distributed among the players who voted for the winner of the battle - Video #002, in exact proportion to their bets! </p>
            <p className={styled.textBronze}>1400 tokens distributed among 200 players (the player who bet more receives more money!)</p>
            <p className={styled.textBronze}>1 Token = 1 EURO on <a href="playporn.bet" target='_blank'>PlayPorn.bet</a></p>
          */}

            {/* <h2 className={styled.subHeader}>Lucky Spin</h2>
          <p className={styled.text}>On PlayPorn.bet, players can instantly multiply their tokens by playing the Lucky Spin.</p>
          <p className={styled.text}>Select one or more models participating in a particular spin, select the amount of the bet and make a spin. If your bet wins it will be multiplied by 4!</p>

          <h2 className={styled.subHeader}>SUPERWET</h2>
          <p className={styled.text}>Players who have earned 3000 tokens in games on PlayPorn.bet have a unique opportunity to order an Exclusive Custom Video from Superwet models based on their own scenario*.</p>
          <p className={styled.text}>* After selecting a model from the list and paying for the service, the PlayPorn.bet manager will contact the player to discuss the script and other details for shooting the video.</p>
          <p className={styled.text}>The price includes the cost of video production in the geographical location of the model at the time of order and the model’s fee. If, according to the player’s scenario, the model must film in another geographical location, the player will have to additionally pay travel expenses.</p>
          <p className={styled.text}>A unique link to the finished exclusive video will be sent to the player within 14 days after payment.</p>
        <p className={styled.text}>Important!!! The Custom Video script will necessarily be consistent with the model and will have to meet safety requirements in its production for performers, as well as be within the limits of “reasonable” without infringing on anyone’s rights and feelings.</p> */}
          </Col>
        </Row>
      </div>
    </>
  );
};

export { GameRules };
